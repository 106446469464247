.knob-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  transition: opacity 0.3s ease-in-out;
  margin-bottom: 10px;
}

.knob-label {
  margin-top: 12px;
  cursor: default;
}

.mini-knob-label {
  margin-top: 12px;
  cursor: default;
  font-size: 0.8em;
}

.knob-container[disabled] {
  opacity: 0.5;
}

.knob-outer {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border: 1px solid rgba(127, 127, 127, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
}

.mini-knob-outer {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border: 1px solid rgba(127, 127, 127, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
}

.knob-dot {
  width: 33px;
  height: 33px;
  position: absolute;
  font-size: 2.0em;
  line-height: 1.75em;
  cursor: default;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
  text-align: left;
}

.mini-knob-dot {
  width: 25px;
  height: 25px;
  position: absolute;
  font-size: 1.5em;
  line-height: 1.75em;
  cursor: default;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
  text-align: left;
}

.knob-container[disabled] .knob-dot {
  color: dimgray;
}


.knob-dial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(127, 127, 127, 0.35);
  text-align: center;
  font-size: 0.9em;
  vertical-align: middle;
  line-height: 4.5em;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.75);
}

.mini-knob-dial {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(127, 127, 127, 0.35);
  text-align: center;
  font-size: 0.8em;
  vertical-align: middle;
  line-height: 4.5em;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.75);
}

.knob-marks {
  width: 65px;
  position: absolute;
  z-index: 0;
}

.mini-knob-marks {
  width: 50px;
  position: absolute;
  z-index: 0;
}
