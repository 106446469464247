.editor-panel-container {
  border-top: 1px solid dimgray;
  overflow-y: auto;
  transition: 0.3s ease-in-out;
  outline: none;
}

.editor-panel-inner {
  padding: 20px;
}

.editor-panel-inset {
  box-shadow: inset 0 5px 20px rgba(0, 0, 0, 0.6);
}