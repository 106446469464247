.transport-container {
  height: 55px;
  border-bottom: 1px solid dimgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  width: 100%;
}

.transport-stats {
  display: flex;
  justify-content: flex-start;
}

.transport-stats div {
  margin-left: 20px;
  color: dimgray;
  width: auto;
}

.transport-fps-count {
  width: 100%;
  color: dimgray;
  text-align: right;
  margin-right: 20px;
}