.menu-button {
  transition: 0.1s ease-in-out;
  opacity: 1;
  width: 40px;
  height: 40px;
  filter: grayscale(100%);
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-button-container:nth-child(1) .menu-button,
.menu-button-container:nth-child(2) .menu-button,
.menu-button-container:nth-child(6) .menu-button,
.menu-button-container:nth-child(7) .menu-button,
.menu-button-container:nth-child(8) .menu-button {
  opacity: 0.35;
}

.menu-button-active {
  opacity: 1;
  filter: grayscale(0);
}

.menu-button-container:nth-child(1) .menu-button-active,
.menu-button-container:nth-child(2) .menu-button-active,
.menu-button-container:nth-child(6) .menu-button-active,
.menu-button-container:nth-child(7) .menu-button-active,
.menu-button-container:nth-child(8) .menu-button-active {
  opacity: 1;
}

.menu-button-separator {
  border-bottom: 1px solid #383838;
}

.menu-button-icon {
  width: 32px;
}
