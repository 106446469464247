body {
  margin: 0;
  padding: 0;
  background-color: #444;
  font-family: sans-serif;
  font-size: 0.8em;
  user-select: none;
  color: darkgray;
  background-image: url(./assets/backgrounds/concrete_wall.png);
}

input[type="text"],
input[type="number"],
select {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid dimgray;
  border-radius: 0;
  outline: 0;
  color: darkgray;
}

input {
  padding: 5px;
}

input:disabled {
  opacity: 0.5;
}

select:disabled {
  opacity: 0.5;
  color: rgba(127, 127, 127, 0.7);
}

label[disabled] {
  opacity: 0.5;
  color: rgba(127, 127, 127, 0.7);
}

input[type="radio"]:after {
  width: 12px;
  height: 12px;
  background-color: #333;
  border: 1px solid dimgray;
  top: -1px;
  left: -1px;
  border-radius: 15px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
  outline: 0;
  position: relative;
}

input[type="radio"]:checked:after {
  background-color: #333;
  border: 1px solid darkorange;
  top: -1px;
  left: -1px;
}

.canvas {
  background-image: url(./assets/backgrounds/batthern.png);
  -webkit-box-shadow: inset 0px 0px 34px 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 0px 34px 5px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 0px 34px 5px rgba(0, 0, 0, 0.4);
}

.main-body-container {
  display: flex;
  flex-direction: row;
}

.main-container > div {
  position: relative;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  align-items: center;
}

.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.error-message {
  width: 800px;
  margin: 160px auto;
  text-align: center;
}

.error-message img {
  opacity: 0.5;
}

.error-message div {
  margin-top: 48px;
  opacity: 0.5;
  color: #ccc;
  font-size: 1.7em;
}
