.list-item-container {
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: default;
  border: 1px solid rgba(0, 0, 0, 0);
  margin-top: -1px;
  margin-left: -1px;
}

.list-item-container:last-child {
  margin-bottom: 15px;
}

.list-item-container:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.list-item-selected {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid dimgray;
  margin-top: -1px;
  margin-left: -1px;
}

.list-item-name {
  margin-left: 10px;
}

.list-item-icon {
  opacity: 0;
  margin-left: 10px;
  transition: 0.1s ease-in-out;
  width: 10px;
  height: 10px;
}

.list-item-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.list-item-container:hover .list-item-icon {
  opacity: 1;
}

.list-item-icon:hover {
  color: darkorange;
}
